<template>
  <MainLayout>
    <div class="columns">
      <div class="column is-8-desktop is-offset-2-desktop is-12-mobile registered">
        <div class="registered-title">
          ¡Gracias por agendar! En breve nos pondremos en contacto contigo.
        </div>
        <div class="registered-description">
          Si lo prefieres puedes contactarnos por WhatsApp o conocer más de nuestra plataforma en
          <a href="http://www.peopleware.com.mx" target="_blank">www.peopleware.com.mx</a>
        </div>
        <div class="registered-buttons columns">
          <a href="/" class="button back-button is-6-desktop is-12-mobile" @click="goBack">
            Volver
          </a>
          <button class="button contact-button is-6-desktop is-12-mobile" @click="openWhatsappApi">
            Contactar por whatsapp
          </button>
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from '@/views/MainLayout';

export default {
  name: 'ThankYou',
  components: { MainLayout },
  methods: {
    goBack() {
      // e.preventDefault();
      // console.log(this.$root.currentRoute);
      // this.$root.currentRoute = '/';
      // window.location.href('/');
      // window.history.pushState(null, 'Home', '/');
    },
    openWhatsappApi() {
      const win = window.open('https://wa.link/o93rg3', '_blank');
      win.focus();
    },
  },
};
</script>

<style lang="scss">
@media #{$mobile-screen} {
  .registered-buttons {
    .contact-button,
    .back-button {
      margin: 0 !important;
    }
    .contact-button {
      margin-top: 16px !important;
    }
    .button {
      height: 2.8rem !important;
    }
  }
  .registered {
    justify-content: start !important;
    &-title,
    &-description,
    &-buttons {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
  }
}

.registered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: translateY(-10%);
  &-title {
    color: #142452;
    font-size: 32px;
    line-height: 1.2;
    font-weight: 800;
    padding-left: 64px;
    padding-right: 64px;
  }
  &-description {
    color: #727c97;
    font-size: 18px;
    margin-top: 24px;
    padding-left: 64px;
    padding-right: 64px;
    //a {
    //  //color: inherit;
    //  text-decoration: underline #727c97;
    //}
  }
  .registered-buttons {
    width: 100%;
    margin-top: 32px;
    padding-left: 32px;
    padding-right: 32px;
    .button {
      width: 100%;
      color: white;
      font-size: 18px;
      font-weight: 600;
      height: 2.3rem;
    }
    .back-button {
      background-color: #4262ff;
      margin-right: 6px;
    }
    .contact-button {
      background-color: #06d755;
      margin-left: 6px;
    }
  }
}
</style>
