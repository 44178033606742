<template>
  <div class="container features">
    <div class="header">
      <div class="header__title pw-title-xl">
        Todo lo que necesitas para gestionar tu organización
      </div>
      <div class="header__subtitle pw-description-l">
        Optimiza los procesos de tu empresa y dispara la productividad de tu equipo.
      </div>
    </div>
    <div class="columns is-multiline">
      <div
        v-for="(feat, index) in features"
        class="column is-3-desktop is-12-mobile feature-card"
        :key="index"
      >
        <img :src="feat.image" alt="" />
        <h2 class="pw-title-l">{{ feat.title }}</h2>
        <p class="pw-description-s">{{ feat.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeatureSection',
  data() {
    return {
      features: [
        {
          image: require('../../assets/images/features/consolida-capital-humano.svg'),
          title: 'Consolida tu capital humano',
          description:
            'Centraliza la información de los colaboradores de todas tus unidades de negocio en una sola plataforma.',
        },
        {
          image: require('../../assets/images/features/digitaliza-expedientes.svg'),
          title: 'Digitaliza tus expedientes',
          description:
            'Configura, genera y resguarda cualquier formato y contrato de RRHH de forma individual o masiva.',
        },
        {
          image: require('../../assets/images/features/controla-presupuestos.svg'),
          title: 'Controla presupuestos de staffing',
          description: 'Controla presupuestos y gestiona tu staffing eficientemente.',
        },
        {
          image: require('../../assets/images/features/calcula-tu-nomina.svg'),
          title: 'Calcula tu nómina eficientemente',
          description:
            'Calcula miles de nómina en minutos, sin importar la complejidad. Desde la prenómina hasta el timbrado.',
        },
        {
          image: require('../../assets/images/features/controla-asistencia.svg'),
          title: 'Controla la asistencia fácilmente',
          description:
            'Controla el acceso y asistencia de los colaboradores con dispositivos biométricos sin contacto.',
        },
        {
          image: require('../../assets/images/features/automatiza-workflows.svg'),
          title: 'Automatiza con workflows',
          description:
            'Automatiza los flujos de solicitud y autorización de vacaciones, ausencias, tiempo extraordinario y mucho más.',
        },
        {
          image: require('../../assets/images/features/timbra-tu-nomina.svg'),
          title: 'Timbra tu nómina masivamente',
          description:
            'Timbra, descarga, imprime y envía miles de comprobantes de nómina con un par de clics.',
        },
        {
          image: require('../../assets/images/features/sat.svg'),
          title: 'Plataforma alineada al SAT',
          description:
            'Cumple con los requerimientos fiscales fácilmente y evita diferencias ante el SAT.',
        },
      ],
    };
  },
  computed: {
    isDevice() {
      return window.innerWidth <= 667;
    },
  },
};
</script>

<style lang="scss">
@media #{$mobile-screen} {
  .features {
    .header__title {
      margin-bottom: 12px !important;
    }
  }
}
.features {
  .header {
    text-align: center;
    margin-bottom: 0.5rem;
    &__title {
      margin-bottom: 0;
      margin-left: -4px;
      margin-right: -4px;
    }
  }
  .feature-card {
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 24px;

    h2 {
      margin-top: 8px;
    }
  }
}
</style>
