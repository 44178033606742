<template>
  <div class="faqs">
    <div v-for="(faq, index) in faqs" :key="index" class="faq" data-aos="fade-up">
      <div class="faq__question pw-title-s">
        <p @click="() => (faq.isOpen = !faq.isOpen)">{{ faq.title }}</p>
        <div
          :class="{ 'faq-open': faq.isOpen }"
          style="display: flex; align-items: center"
          @click="() => (faq.isOpen = !faq.isOpen)"
        >
          <img :src="require('./../assets/images/chevron-down.png')" alt="arrow" />
        </div>
      </div>
      <div class="faq__container">
        <div class="faq__content pw-description-l" :class="{ 'faq__content-opened': faq.isOpen }">
          <div v-html="faq.content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Faqs',
  props: {
    faqs: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.faq-open {
  img {
    transform: rotate(180deg);
  }
}
@media #{$mobile-screen} {
  .faq {
    &__question {
      p {
        line-height: 28px !important;
      }
    }
  }
}
.faqs {
  padding-top: 32px;
}
.faq {
  border-bottom: 1px solid #d8e3eb;
  margin-bottom: 12px;
  padding-bottom: 12px;
  &__question {
    display: flex;
    justify-content: center;
    p {
      flex: auto;
      line-height: 44px;
    }
  }
  &__container {
    //&:first-child {
    overflow: hidden;
    //}
  }
  &__content {
    //opacity: 0;
    display: none;
    //transition: opacity 600ms ease-in-out, height 600ms ease-out;
    //margin-top: -10%;
    //margin-bottom: -10%;
    transform: translateY(-100%);
    transition: all 600ms cubic-bezier(0, 1.11, 0.43, 0.97);
    &-opened {
      //opacity: 1;
      display: block;
      transform: translateY(0);
      margin-top: 0;
      //transition: all 600ms ease-in;
    }
  }
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
</style>
