<template>
  <div class="pricing-card">
    <div class="pricing-title">{{ pricingItem.title }}</div>
    <div class="pricing-extra">
      <slot />
    </div>
    <p class="rent-title">{{ pricingItem.rentTitle }}</p>
    <p class="rent-total">{{ pricingItem.rentTotal }}</p>
    <template v-for="(section, index) in pricingItem.sections">
      <div class="item-title" :key="`${pricingItem.title}item${index}`">{{ section.title }}</div>
      <div
        class="items"
        :key="`${pricingItem.title}description${index}-${index2}`"
        v-for="(description, index2) in section.descriptions"
      >
        <i class="mdi mdi-check-circle"></i>{{ description }}
      </div>
    </template>
    <p class="pricing-card-footer">{{ pricingItem.footerAdvice }}</p>
    <div class="pricing-card-button">
      <slot name="footerButton">
        <button class="demo-button" @click="$root.$emit('scrollToForm')">Agendar una demo</button>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PricingCard',
  props: {
    pricingItem: Object,
  },
};
</script>

<style lang="scss">
@media #{$mobile-screen} {
  .pricing-card {
    width: auto !important;
    margin-left: -52px;
    margin-right: -52px;
  }
}

.pricing-card {
  margin-top: 2rem;
  padding: 32px;
  background-color: white;
  border-radius: 12px;
  display: flex;
  width: 100%;
  flex-direction: column;

  .item-title {
    margin-top: 12px;
    color: #142452;
    font-size: 20px;
    font-weight: 800;
  }
  .items {
    color: #727c97;
    font-size: 16px;
    .mdi {
      font-size: 20px;
      color: #0bc148;
      margin-right: 12px;
    }
  }
  .rent-title {
    color: #727c97;
    font-size: 18px;
    font-weight: bold;
  }
  .rent-total {
    color: #142452;
    font-size: 34px;
    font-weight: 800;
  }
  &-footer {
    color: #727c97;
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 24px;
  }
  &-button {
    width: 100% !important;
    margin-top: auto !important; // To display button to the bottom of card
    .demo-button {
      width: 100%;
      border-radius: 18px;
    }
  }
}
.pricing-title {
  font-size: 28px;
  font-weight: 800;
  color: #142452;
}
</style>
