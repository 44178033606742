<template>
  <div class="pw-hero">
    <div class="container">
      <div class="columns">
        <div class="column is-6">
          <div class="pw-hero__text">
            <h2 v-html="topTitle" />
            <h1 class="pw-title-xl" v-html="title" />
            <p v-html="description" />
            <button class="demo-button" @click="$root.$emit('scrollToForm')">
              Agendar una demo
            </button>
          </div>
        </div>
        <div class="column pw-hero-image">
          <img :src="image" alt="Peopleware Dashboard" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero',
  props: {
    title: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    topTitle: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
@media #{$mobile-screen} {
  .pw-hero {
    padding-top: 0;
    br {
      display: none !important;
    }
    margin-top: -32px;
    margin-bottom: 64px;
  }
}

.pw-hero {
  min-height: 80vh;
  display: flex;
  align-items: start;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  transform: translateY(-10vh);
  &__text {
    h2 {
      color: #a5acbc;
      font-size: 20px;
      font-weight: 700;
    }
    p {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 24px;
    }
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
