<template>
  <MainLayout>
    <Hero class="info-hero" v-bind="hero" />
    <VideoSection class="video-section-margin" />
    <FeatureSection />
    <br /><br /><br />
    <PricingSection />
    <br /><br />
    <UsersReview />
    <div class="columns">
      <div class="column is-11 is-offset-1">
        <RequestForm class="request-form" />
      </div>
    </div>
    <div class="platform-faqs">
      <div class="container">
        <h2 class="pw-title-xl">Preguntas frecuentes</h2>
        <Faqs :faqs="faqs" />
      </div>
    </div>
  </MainLayout>
</template>

<script>
import Hero from '@/components/Hero';
import FeatureSection from '@/views/Home/FeatureSection';
import PricingSection from '@/views/Home/PricingSection';
import UsersReview from '@/views/Home/UsersReview';
import RequestForm from '@/views/Home/RequestForm';
import Faqs from '@/components/Faqs';
import MainLayout from '@/views/MainLayout';
import VideoSection from '@/views/Home/VideoSection';

export default {
  name: 'Home',
  components: {
    VideoSection,
    MainLayout,
    Faqs,
    RequestForm,
    UsersReview,
    PricingSection,
    FeatureSection,
    Hero,
  },
  data() {
    return {
      hero: {
        image: require('../../assets/images/hero-peopleware.svg'),
        title: 'La forma más fácil de gestionar tu capital humano y nómina',
        topTitle: '',
        description:
          'Centraliza y automatiza tus procesos de RRHH y nóminas,' +
          ' sin importar el giro o tamaño de tu organización, en una sola plataforma en la nube.',
      },
      faqs: [
        {
          title: '¿Existe algún costo por implementación o activación del servicio?',
          content:
            'Depende del número de colaboradores y del volumen de históricos que desees migrar a la plataforma. Se podrá cobrar o no, un costo de implementación por colaborador activo en la carga inicial de datos. Esto se paga una sola vez al contratar Peopleware. contáctanos para darte un presupuesto personalizado.',
          isOpen: false,
        },
        {
          title: '¿Cómo se factura la renta mensual de Peopleware?',
          content:
            'Se factura una renta por colaborador activo por mes. Consulta nuestra política de precio justo.',
          isOpen: false,
        },
        {
          title: '¿Debo de contar con un mínimo de colaboradores para contratar Peopleware?',
          content:
            'Puedes contratar Peopleware sin importar el número de colaboradores de tu organización, pero al ser una plataforma Software as a Service 100% en la nube, nuestra facturación base es de 30 colaboradores.',
          isOpen: false,
        },
        {
          title: '¿Cómo se comparan los precios de Peopleware con los de plataformas similares?',
          content:
            'Sabemos que existen otros excelentes productos en el mercado, pero sus precios están fuera del alcance de la mayoría de las empresas, también existen otros con costos muy accesibles, pero solo cubren parte del proceso de recursos humanos y nóminas, Peopleware es una plataforma integral de primer nivel con precios accesibles para cualquier empresa.',
          isOpen: false,
        },
        {
          title: '¿Qué métodos de pago acepta Peopleware?',
          content:
            'Puedes realizar el pago de tu renta mensual con tarjeta de crédito o transferencias bancarias.',
          isOpen: false,
        },
        {
          title: '¿Cuánto tiempo lleva implementar Peopleware?',
          content:
            'Dependiendo del número de colaboradores, Peopleware se puede implementar en un tiempo promedio de 4 semanas o menos. Muchos de nuestros clientes empiezan a usar la plataforma el mismo día de la contratación.',
          isOpen: false,
        },
        {
          title: '¿Tengo que firmar un contrato a largo plazo?',
          content:
            'No, solo necesitas aceptar los términos y condiciones de nuestra plataforma. El servicio de Peopleware no tiene un plazo forzoso, puedes cancelar tu cuenta en cualquier momento y solo se facturará dicho mes. Tu cuenta permanecerá activa hasta final de dicho mes y no generará costos adicionales a los establecidos.',
          isOpen: false,
        },
        {
          title: '¿Cómo funciona el soporte técnico?',
          content:
            'Nuestro equipo de soporte siempre estará disponible para apoyarte en cualquier momento que los necesites. Cada uno de ellos puede resolver cualquier problema que se te presente. Contamos con soporte vía telefónica, WhatsApp y Telegram.',
          isOpen: false,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@media #{$mobile-screen} {
  .platform-faqs {
    margin-top: -12px !important;
    .pw-title-xl {
      margin-left: -12px;
      margin-right: -12px;
    }
  }
  .request-form {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.platform-faqs {
  margin-top: 64px;
  margin-bottom: 56px;
  h2 {
    text-align: center;
  }
}
.request-form {
  margin-left: -24px;
  margin-right: -24px;
}
.video-section-margin {
  margin-bottom: 112px !important;
}
</style>

<style lang="scss" scoped>
@media #{$mobile-screen} {
  .info-hero {
    margin-top: 120px;
    ::v-deep {
      .pw-hero-image {
        transform: translateY(0) !important;
      }
      overflow: visible;
    }
  }
  //margin-bottom: 0 !important;
}
.info-hero {
  ::v-deep {
    .pw-hero-image {
      transform: translateY(-18%);
    }
    overflow: visible;
  }
}
</style>
