<template>
  <div id="app">
    <Navbar />
    <div class="main-view">
      <slot />
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar';

export default {
  name: 'MainLayout',
  components: {
    Navbar,
  },
};
</script>

<style lang="scss">
@import '~@mdi/font/css/materialdesignicons.min.css';
@import '~bulma';
@import './src/scss/all';
</style>
