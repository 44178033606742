import Vue from 'vue';
import Home from '@/views/Home/Home';
import ThankYou from './views/ThankYou';
import NotFound from '@/views/NotFound';
import VueScrollTo from 'vue-scrollto';

Vue.use(VueScrollTo);
Vue.config.productionTip = false;

const routes = {
  '/': Home,
  '/thank-you': ThankYou,
};

new Vue({
  el: '#app',
  data: {
    currentRoute: window.location.pathname,
  },
  computed: {
    ViewComponent() {
      return routes[this.currentRoute] || NotFound;
    },
  },
  render(h) {
    return h(this.ViewComponent);
  },
}).$mount('#app');
