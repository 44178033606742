<template>
  <nav class="navbar is-fixed-top">
    <div class="container">
      <div class="navbar-brand">
        <a href="http://www.peopleware.com.mx">
          <img
            alt="Peopleware Logotipo"
            :src="require('./../assets/Logo.svg')"
            class="is-hidden-mobile logo"
          />
          <img
            alt="Peopleware Logotipo"
            :src="require('./../assets/ShortLogo.svg')"
            class="is-hidden-desktop logo-short"
          />
        </a>
      </div>
      <div class="navbar-end">
        <a href="https://www.peopleware.app/">
          <span class="button login-button">Iniciar sesión</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      showMenu: false,
    };
  },
  watch: {
    $route() {
      this.showMenu = false;
    },
  },
};
</script>

<style lang="scss">
@media #{$mobile-screen} {
  .navbar {
    //border-color: transparent !important;
    border-bottom: 1px solid #d8e3eb;

    &-brand {
      margin: 0 !important;
    }

    &-end {
      position: fixed;
      margin-top: -52px;
      margin-right: 16px !important;
      right: 0;
      //float: left;
    }

    &-start {
      margin: 0 !important;
    }
  }
}
.navbar {
  border-bottom: 1px solid #d8e3eb;
  padding: 10px;
  &-start {
    margin-left: 50px;
  }
  &-end {
    margin-right: 64px;
  }
}
.navbar-brand {
  .logo {
    width: 160px !important;
    margin-left: 20px;
    margin-top: 12px;
    &-short {
      width: 40px !important;
      margin-top: 12px;
    }
  }
}
.login-button {
  color: #4262ff !important;
  background-color: #e7ebff !important;
  border-color: #e7ebff !important;
  border-radius: 8px !important;
  &:active {
    background-color: #9fafff !important;
    border-color: #9fafff !important;
  }
}
</style>
