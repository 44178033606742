<template>
  <div class="pricing">
    <div class="header">
      <div class="header__title">Elige tu plan ideal</div>
      <div class="header__subtitle">
        Accede a Peopleware hoy mismo. Tenemos un plan diseñado especialmente para tu
        <br class="is-hidden-mobile" />
        organización. ¡Apresúrate! estas tarifas estarán disponibles por tiempo limitado.
      </div>
    </div>
    <div class="container">
      <div
        class="columns is-variable is-1-mobile is-0-tablet is-3-desktop is-8-widescreen is-2-fullhd"
      >
        <div class="column is-6-desktop is-full-mobile">
          <PricingCard :pricingItem="pricingList.enterprise">
            <template>
              <p class="rent-title">Selecciona un plan:</p>
              <div class="buttons">
                <div
                  class="button custom-button"
                  :class="{ 'is-active': currentPrice === price }"
                  v-for="(price, index) in enterprisePrices"
                  :key="index"
                  @click="setPrice(price)"
                >
                  {{ price }}
                </div>
              </div>
            </template>
          </PricingCard>
        </div>
        <div class="column is-6-desktop is-full-mobile is-flex-desktop">
          <PricingCard :pricingItem="pricingList.outsourcing" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PricingCard from '@/components/PricingCard';
export default {
  name: 'PricingSection',
  components: { PricingCard },
  data() {
    return {
      currentPrice: 'Pyme',
      enterprisePrices: ['Pyme', 'Professional', 'Enterprise'],
      rentList: {
        Pyme: '',
        Professional: '',
        Enterprise: '',
      },
      featuresList: {
        Pyme: [
          'Una empresa (Una empresa RFC)',
          'Hasta 50 colaboradores',
          'Hasta 3 usuarios',
          'Timbres incluidos',
          'Implementación sin costo',
          'Soporte técnico personalizado',
        ],
        Professional: [
          'Empresas ilimitadas',
          'Colaboradores ilimitados',
          'Usuarios ilimitados',
          'Timbres $0.99 MXN c/u',
          'Implementación sin costo',
          'Soporte técnico personalizado',
        ],
        Enterprise: [
          'Empresas ilimitadas',
          'Colaboradores ilimitados',
          'Usuarios ilimitados',
          'Timbres $0.99 MXN c/u',
          'Implementación sin costo',
          'Soporte técnico personalizado',
        ],
      },
      appsList: {
        Pyme: ['Core*', 'Recursos Humanos*', 'Seguridad Social*', 'Nómina*'],
        Professional: ['Core', 'Recursos Humanos', 'Seguridad Social', 'Nómina'],
        Enterprise: [
          'Core',
          'Recursos Humanos',
          'Seguridad Social',
          'Nómina',
          'Tiempo y asistencia',
        ],
      },
      pricingList: {
        enterprise: {
          title: 'Planes empresariales',
          rentTitle: 'Renta mensual.',
          rentTotal: '',
          sections: [
            {
              title: 'Funcionalidades incluidas',
              descriptions: [
                'Una empresa (Una empresa RFC)',
                'Hasta 50 colaboradores',
                'Hasta 3 usuarios',
                'Timbres incluidos',
                'Implementación sin costo',
                'Soporte técnico personalizado',
              ],
            },
            {
              title: 'Aplicaciones',
              descriptions: ['Core*', 'Recursos Humanos*', 'Seguridad Social*', 'Nómina*'],
            },
          ],
          footerAdvice: '* Funciones esenciales',
        },
        outsourcing: {
          title: 'Plan outsourcing',
          rentTitle: 'Renta mensual por colaborador.',
          rentTotal: '',
          sections: [
            {
              title: 'Funcionalidades incluidas',
              descriptions: [
                'Empresas ilimitadas (RFC)',
                'Colaboradores ilimitados',
                'Usuarios ilimitados',
                'Timbres $0.99 MXN',
                'Implementación sin costo',
                'Soporte técnico personalizado',
              ],
            },
            {
              title: 'Módulos incluidos',
              descriptions: [
                'Directorio de colaboradores',
                'Historial de trabajo',
                'Contratos y documentos',
                'Movimientos de Seguridad Social',
                'Ausentismos e incapacidades',
                'Gestión de préstamos',
                'Reportes, informes y layouts',
                'Cálculo y timbrado de nómina',
              ],
            },
          ],
        },
      },
    };
  },
  methods: {
    setPrice(priceName) {
      this.currentPrice = priceName;

      this.pricingList.enterprise.rentTitle =
        priceName === 'Pyme' ? 'Renta mensual fija.' : 'Renta mensual por colaborador.';

      this.pricingList.enterprise.footerAdvice =
        priceName === 'Pyme' ? '* Funciones esenciales' : '';

      this.pricingList.enterprise.rentTotal = this.rentList[priceName];
      this.pricingList.enterprise.sections[0].descriptions = this.featuresList[priceName];
      this.pricingList.enterprise.sections[1].descriptions = this.appsList[priceName];
    },
  },
};
</script>

<style lang="scss">
@media #{$mobile-screen} {
  .pricing {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.pricing {
  width: auto !important;
  background-color: #142452;
  padding: 32px;
  margin-left: -120px;
  margin-right: -120px;

  .header {
    text-align: center;
    color: white;
    &__title {
      font-size: 32px;
      font-weight: 800;
    }
  }
  .buttons {
    margin-top: 8px;
    margin-bottom: 16px !important;
  }
  .custom-button {
    color: #4262ff !important;
    font-weight: 600;
    border-color: #ced6ff !important;
  }
  .is-active {
    background-color: #e7ebff;
  }
}
</style>
