<template>
  <div class="container columns">
    <div class="column is-6 pw-title-xl is-hidden-desktop has-text-centered">
      Conoce las funcionalidades de Peopleware
    </div>
    <div class="column is-6">
      <video
        poster="@/assets/video-thumbnail.png"
        src="@/assets/Peopleware Institucional.mp4"
        controls
      />
    </div>
    <div class="column is-6 video-description">
      <div class="pw-title-xl is-hidden-mobile">Conoce las funcionalidades de Peopleware</div>
      <div class="pw-description-l info">
        Gestiona los procesos de capital humano, nómina, timbrado de recibos CFDI, tiempo &
        asistencia, gestión de caja de ahorro y más. Todo en una sola plataforma, en la nube.
      </div>
      <button class="demo-button" @click="$root.$emit('scrollToForm')">Agendar una demo</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoSection',
};
</script>

<style lang="scss">
.video-description {
  padding: 16px;
  .info {
    margin-bottom: 24px;
  }
}
</style>
