<template>
  <div class="form">
    <div class="columns">
      <div class="column is-4 form-info is-hidden-mobile">
        <div class="form-info__photo">
          <img :src="require('../../assets/images/Antonio.jpeg')" alt="Antonio" />
        </div>
        <div class="form-info__title">
          ¡Agenda una demo de 30 min con nosotros y conoce el funcionamiento de Peopleware!
        </div>
        <div class="form-info__description">
          ¡Hola! soy Antonio, completa este formulario y me pondré en contacto contigo para
          coordinar la fecha y hora de la demo.
        </div>
      </div>
      <div class="column is-6-desktop is-offset-2-desktop is-12-mobile form-request" id="form">
        <div class="form-request__title">Agendar una demo</div>
        <div class="column is-full form-info is-hidden-desktop">
          <div class="form-info__photo">
            <img :src="require('../../assets/images/Antonio.jpeg')" alt="Antonio" />
          </div>
          <div class="form-info__title">
            ¡Agenda una demo de 30 min con nosotros y conoce el funcionamiento de Peopleware!
          </div>
          <div class="form-info__description">
            ¡Hola! soy Antonio, completa este formulario y me pondré en contacto contigo para
            coordinar la fecha y hora de la demo.
          </div>
        </div>
        <form action="#" @submit="sendMessage">
          <div class="columns is-multiline">
            <div class="column is-6-desktop is-12-mobile">
              <div class="form-request__input">
                <label for="Name">Nombre</label>
                <input id="Name" v-model="request.Name" type="text" required />
              </div>
            </div>
            <div class="column is-6-desktop is-12-mobile">
              <div class="form-request__input">
                <label for="LastName">Apellido</label>
                <input id="LastName" v-model="request.LastName" type="text" />
              </div>
            </div>
            <div class="column is-12">
              <div class="form-request__input">
                <label for="Email">Correo empresarial</label>
                <input id="Email" v-model="request.Email" type="email" required />
              </div>
            </div>
            <div class="column is-12">
              <div class="form-request__input">
                <label for="PhoneNumber">Número telefónico</label>
                <input
                  id="PhoneNumber"
                  v-model="PhoneNumberFormatted"
                  type="tel"
                  pattern="[0-9]{10}"
                  maxlength="10"
                  required
                  @keypress="isNumber"
                />
              </div>
            </div>
            <div class="column is-12">
              <div class="form-request__input">
                <label for="CompanyName">Nombre de tu empresa</label>
                <input id="CompanyName" v-model="request.CompanyName" type="text" required />
              </div>
            </div>
          </div>
          <button
            id="submit"
            class="demo-button"
            style="margin-top: 24px; width: 100%"
            :disabled="sending"
            type="submit"
          >
            Agendar una demo
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RequestForm',
  data() {
    return {
      animating: false,
      sending: false,
      request: {
        Name: '',
        LastName: '',
        Email: '',
        PhoneNumber: '',
        CompanyName: '',
      },
      formURL: 'https://api.staticforms.xyz/submit',
      hubspotFormURL: 'https://submit.jotform.com/submit/210672829020854/',
    };
  },
  mounted() {
    const submit = document.querySelector('#submit');

    this.$root.$on('scrollToForm', this.scrollToForm);
    submit.addEventListener('click', this.setCustomErrorMessages);
    submit.addEventListener('input', this.setCustomErrorMessages);
  },
  beforeDestroy() {
    const submit = document.querySelector('#submit');

    this.$root.$off('scrollToForm', this.scrollToForm);
    submit.removeEventListener('click', this.setCustomErrorMessages);
    submit.removeEventListener('input', this.setCustomErrorMessages);
  },
  methods: {
    sendMessage(e) {
      e.preventDefault();
      this.sending = true;

      const model = {
        name: `${this.request.Name.trim()} ${this.request.LastName.trim()}`,
        email: this.request.Email,
        phone: this.request.PhoneNumber,
        $company: this.request.CompanyName,
        $platform: true,
        $timeattendance: false,
        $stamp: false,
        $savings: false,
        accessKey: 'df576271-a317-48a6-9bdf-3c0a6f34b430',
        subject: 'Contacto desde landing.peopleware.com.mx',
      };
      const hubspotModel = { formID: 210672829020854 };
      hubspotModel['q14_nombre[first]'] = this.request.Name.trim();
      hubspotModel['q14_nombre[last]'] = this.request.LastName.trim();
      hubspotModel['q3_correoEmpresarial3'] = model.email;
      hubspotModel['q15_numeroTelefonico15[full]'] = model.phone;
      hubspotModel['q8_nombreDe8'] = model.$company;

      const hubspotFormBody = [];
      for (const prop in hubspotModel) {
        const encodedKey = encodeURIComponent(prop);
        const encodedValue = encodeURIComponent(hubspotModel[prop]);
        hubspotFormBody.push(encodedKey + '=' + encodedValue);
      }

      // setTimeout(() => {
      //   this.finishRegistration();
      // }, 1000);
      fetch(this.formURL, {
        method: 'post',
        body: JSON.stringify(model),
        headers: { 'Content-Type': 'application/json' },
      }).then(() => {
        fetch(this.hubspotFormURL, {
          method: 'post',
          body: hubspotFormBody.join('&'),
          headers: { 'Content-Type': ' application/x-www-form-urlencoded' },
        })
          .then(() => {
            // console.info('hubspot sent');
            this.finishRegistration();
          })
          .catch(() => {
            // console.warn('there was an error sending to hubspot');
            this.finishRegistration();
          });
      });
    },
    finishRegistration() {
      this.request.Name = '';
      this.request.LastName = '';
      this.request.Email = '';
      this.request.PhoneNumber = '';
      this.request.CompanyName = '';
      this.sending = false;

      this.$nextTick(() => {
        this.$root.currentRoute = '/thank-you';
        window.history.pushState(null, 'ThankYou', '/thank-you');
      });
    },
    setCustomErrorMessages() {
      const email = document.querySelector('#Email');
      const phone = document.querySelector('#PhoneNumber');

      if (phone.validity.patternMismatch) {
        phone.setCustomValidity('Debe contener 10 dígitos');
      } else {
        phone.setCustomValidity('');
      }

      if (email.validity.typeMismatch) {
        email.setCustomValidity("Formato inválido. Ejemplo: 'correo@ejemplo.com'");
      } else {
        email.setCustomValidity('');
      }
    },
    scrollToForm() {
      if (this.animating) return;

      this.animating = true;
      this.$scrollTo('#form', 500, {
        easing: 'easing-in-out',
        offset: -100,
        onDone: () => {
          this.animating = false;
        },
      });
    },
    isNumber(e) {
      if (!/^[0-9]$/.exec(e.key)) {
        e.preventDefault();
      }
    },
  },
  computed: {
    PhoneNumberFormatted: {
      get() {
        // let number = this.request.PhoneNumber.slice(0, 3);
        // if (this.request.PhoneNumber.length >= 4) {
        //   number = `(${number}) `;
        // }
        // number += this.request.PhoneNumber.slice(3, 6);
        // if (this.request.PhoneNumber.length >= 7) {
        //   number += '-';
        // }
        // number += this.request.PhoneNumber.slice(6);
        return this.request.PhoneNumber;
      },
      set(val) {
        this.request.PhoneNumber = val.replaceAll(/[\\(\\)\\\-\\ ]/g, '');
      },
    },
  },
};
</script>

<style lang="scss">
@media #{$mobile-screen} {
  .form-info {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  .form-request {
    margin-top: 24px;
    padding-left: 48px !important;
    padding-right: 48px !important;
    transform: translateX(0) !important;
  }
}

.form {
  margin-bottom: 96px;
}
.form-info {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  &__photo {
    position: relative;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    img {
      border-radius: 50%;
    }
  }
  &__title {
    color: #142452;
    font-size: 18px;
    font-weight: bold;
    margin-top: 24px;
  }
  &__description {
    color: #727c97;
    font-size: 16px;
    margin-top: 12px;
  }
}
.form-request {
  transform: translateX(-20px);
  label {
    display: block;
    color: #142452;
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 4px;
  }
  input {
    width: 100%;
    padding: 0.75em 1.1em;
    background-color: #f2f5f8;
    //border-color: #f2f5f8;
    border: 2px solid var(--input-border);
    border-radius: 8px;
    outline: none;
  }
  &__title {
    color: #142452;
    font-size: 28px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 16px;
  }
}
.custom-dialog {
  border-radius: 12px !important;
  .swal2-title {
    color: #142452;
    font-weight: 800;
  }
  .swal2-icon {
    margin: 0;
  }
  .swal2-icon.swal2-success {
    color: #0bc148;
    font-size: 22px;
    border-color: transparent;
  }
  .swal2-content {
    color: #727c97;
    font-size: 16px;
  }
}
</style>
