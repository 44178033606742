<template>
  <div class="users-review">
    <p class="question">¿Qué opinan nuestros usuarios?</p>
    <div class="columns">
      <div
        class="quote-text column"
        :class="'is-6-desktop is-offset-3-desktop is-10-mobile is-offset-1-mobile'"
      >
        Con Peopleware puedo calcular la nómina de mi equipo fácilmente, desde donde me encuentre y
        en pocos pasos.
      </div>
    </div>
    <div class="reviews">
      <div class="user">
        <div class="user-photo">
          <img :src="require('../../assets/images/users/user-reviewer.png')" alt="Review" />
        </div>
        <div class="user-name">Guillermo Miranda</div>
        <div class="user-job">Analista de nóminas</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UsersReview',
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
.users-review {
  text-align: center;
  margin-top: 96px;
  margin-bottom: 96px;
  .question {
    color: #727c97;
    font-size: 18px;
  }
  .quote-text {
    color: #142452;
    font-size: 22px;
    font-weight: bolder;
    font-style: italic;
  }
  .reviews {
    .user {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &-photo {
        position: relative;
        width: 96px;
        height: 96px;
        border-radius: 50%;
        img {
          border-radius: 50%;
        }
      }
      &-name {
        color: #142452;
        font-size: 22px;
        font-weight: 800;
        margin-top: 12px;
      }
      &-job {
        color: #727c97;
        font-size: 16px;
      }
    }
  }
}
</style>
