<template>
  <MainLayout>No se encontró la ruta</MainLayout>
</template>

<script>
import MainLayout from '@/views/MainLayout';

export default {
  name: 'NotFound',
  components: { MainLayout },
  props: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>

<style lang="scss">
.some-class {
}
</style>
